import { useQuery } from "react-query";
import { useApiDesigner } from "../apirest/useApiDesigner";
import { querysDesigners } from "../Util/NomesQuerysDesigners";
import { useNavigate } from "react-router-dom";

export const useDesigners = () => {
  const api = useApiDesigner();
  const imagensInicial = [
    { src: "", tipo: "imagem", designer: { name: "" }, primeiraLetra: "" },
  ];
  const recuperarDesigners = async () => {
    try {
      const { data } = await api.listar();
      if (data) {
        return data.doc;
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
    return [];
  };

  const { data: designers, isLoading } = useQuery({
    queryFn: async () => await recuperarDesigners(),
    queryKey: [querysDesigners.DESGINERS],
  });

  const recuperarImagensDesigners = () => {
    if (designers) {
      const designerComImagens = designers.filter(
        (designer) => designer.pictures,
      );
      let imagens = designerComImagens.flatMap((designer) =>
        designer.pictures
          .filter((picture) => picture && picture.url)
          .map((picture) => {
            return {
              src: picture.url,
              designer: {
                name: designer.name,
                _id: designer._id,
              },
            };
          }),
      );
      return imagens;
    }
    return imagensInicial;
  };
  const { data: imagens } = useQuery({
    queryFn: () => recuperarImagensDesigners(),
    queryKey: [querysDesigners.IMAGENS],
    enabled: !!designers,
  });

  const ordenarImagensPeloNomeDesigner = () => {
    if (imagens) {
      const imagensOrdenadas = [...imagens];
      return imagensOrdenadas.sort((imagem1, imagem2) =>
        imagem1.designer.name.localeCompare(imagem2.designer.name),
      );
    }
    return imagens;
  };

  const { data: imagensOrdenadaNomeDesigner } = useQuery({
    queryFn: () => ordenarImagensPeloNomeDesigner(),
    enabled: !!imagens,
    queryKey: [querysDesigners.IMAGENS_ORDENADAS_NOME_DESIGNER],
  });

  const retornarImagensComPrimeiraLetra = () => {
    if (imagensOrdenadaNomeDesigner) {
      const letrasUsadas = new Set();
      const imagensPrimeiroNome = [...imagensOrdenadaNomeDesigner];
      return imagensPrimeiroNome.map((imagem) => {
        const designer = imagem.designer;
        const primeiraLetra = designer.name.charAt(0).toUpperCase();
        // Adiciona a propriedade 'primeiraLetra' à primeira imagem do primeiro designer de cada letra
        if (!letrasUsadas.has(primeiraLetra)) {
          letrasUsadas.add(primeiraLetra);
          return { ...imagem, primeiraLetra: primeiraLetra };
        }
        return imagem;
      });
    }
    return imagensOrdenadaNomeDesigner;
  };

  const { data: imagensOrdenadasComPrimeiraLetra } = useQuery({
    queryFn: () => retornarImagensComPrimeiraLetra(),
    queryKey: [
      querysDesigners.IMAGENS_ORDENADAS_NOME_DESIGNER_PRIMEIRA_LETRA_DESIGNER,
    ],
    enabled: !!imagensOrdenadaNomeDesigner,
  });

  const navigate = useNavigate();

  const aoClicarImagemDesigner = (imagem) => {
    const primeiraLetra = imagem.designer.name.charAt(0).toUpperCase();
    navigate(`/designersPorLetra?primeiraLetra=${primeiraLetra}`);
  };

  const selecionarDesigner = (imagem) => {
    navigate(`/designer?designerId=${imagem.designer._id}`);
  };

  return {
    carregando: isLoading,
    imagens: imagens ?? imagensInicial,
    designers: designers ?? [],
    imagensOrdenadasComPrimeiraLetra: imagensOrdenadasComPrimeiraLetra ?? [],
    aoClicarImagemDesigner,
    imagensOrdenadaNomeDesigner,
    designers,
    selecionarDesigner,
  };
};
