import { useNavigate, useSearchParams } from "react-router-dom";
import { useApiProduto } from "../apirest/useApiProduto";
import { useQuery } from "react-query";
import { querysTelaProdutos } from "../Util/NomesQuerysProdutos";
import { useEffect, useState } from "react";
import { useMobile } from "./useMobile";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { mensagemObrigatorio } from "../constants/Mensages";
import { useApiSolicitacaoOrcamento } from "../apirest/useApiSolicitacaoOrcamento";
import { irParaTopo } from "../Util/IrParaTopo";
import { urlWpp } from "../Util/UrlWhatssapp";
export const useDetalheProduto = () => {
  const [searchParams] = useSearchParams();
  const produtoId = searchParams.get("produtoId");
  const api = useApiProduto();
  const listaImagemInicial = [{ src: "", tipo: "imagem" }];
  const [midiaPrincipal, setMidiaPrincipal] = useState({
    url: "",
    tipo: "video" | "imagem",
    informadaPeloUsuario: false,
  });
  const [listaImagens, setListaImagens] = useState(listaImagemInicial);
  const [exibeDetalhesProduto, setExibeDetalhesProduto] = useState(true);
  const [exibeOrcamento, setExibeOrcamento] = useState(false);
  const { isMobile } = useMobile();
  useEffect(() => {
    if (isMobile) {
      setExibeOrcamento(true);
    }
  }, [isMobile]);
  const produtoInicial = {
    name: "",
    variants: [
      {
        pictures: [
          {
            url: "",
          },
        ],
      },
    ],
    designer: {
      name: "",
    },
  };

  const recuperarImgPrincipal = (produto) => {
    return produto.variants[0].pictures[0].url;
  };
  const retornaMidiaPrincipalProduto = (produto) => {
    return {
      url: recuperarImgPrincipal(produto),
      tipo: "imagem",
      informadaPeloUsuario: false,
    };
  };
  const recuperaListaImagensProduto = (produto) => {
    let imagens = produto.variants.flatMap((variant) => variant.pictures);
    imagens = imagens.filter((imagem) => imagem && imagem.url);
    let videos = produto.variants.flatMap((variant) => variant.videos);
    videos = videos.filter((video) => video && video.url);
    if (imagens && imagens.length > 0) {
      const lista = imagens.map((imagem) => {
        return { src: imagem.url };
      });

      if (videos && videos.length > 0) {
        videos.forEach((video) =>
          lista.push({ src: video.url, tipo: "video", desabilitado: true }),
        );
      }
      return lista;
    }
    return listaImagemInicial;
  };
  const [carregando, setCarregando] = useState(false);

  const alterarMidiaPrincipal = (url, tipo = "imagem") => {
    setMidiaPrincipal({ url, tipo });
    irParaTopo();
  };

  const recuperarProdutoPorId = async () => {
    if (produtoId) {
      try {
        setCarregando(true);
        const { data } = await api.recuperarPorId(produtoId);
        const produto = data.doc;
        alterarMidiaPrincipal(recuperarImgPrincipal(produto));
        setListaImagens(recuperaListaImagensProduto(produto));
        if (!isMobile) {
          setExibeOrcamento(false);
          setExibeDetalhesProduto(true);
        }
        return produto;
      } catch (e) {
        console.log(e);
      } finally {
        setCarregando(false);
      }
    }
    return produtoInicial;
  };

  const { data: produtoApi } = useQuery({
    queryKey: [querysTelaProdutos.PRODUTO_ID, produtoId],
    queryFn: async () => await recuperarProdutoPorId(),
  });

  const origem = searchParams.get("origem");
  const designerId = searchParams.get("designerId");
  const navigate = useNavigate();
  const voltar = () => {
    let url = origem ?? "produtos";
    if (origem === "designer" && designerId) {
      url += "?designerId=" + designerId;
    }
    navigate(`/${url}`);
  };

  const produto = produtoApi ?? produtoInicial;
  const possuiListaImagem =
    listaImagens && listaImagens > 0 && listaImagens[0].url;

  const aoClicaNaImagem = (imagem) => {
    if (!imagem.tipo || imagem.tipo != "video") {
      alterarMidiaPrincipal(imagem.src);
    } else if (imagem.tipo == "video") {
      alterarMidiaPrincipal(imagem.src, imagem.tipo);
    }
  };

  const aoClicarEmSolicitarOrcamento = () => {
    setExibeOrcamento(true);
    setExibeDetalhesProduto(false);
  };
  const exibeBotaoSolicitarOrcamento = !exibeOrcamento;
  const schemaProduto = yup.object().shape({
    nome: yup.string().required(mensagemObrigatorio("nome")),
    email: yup.string().required(mensagemObrigatorio("e-mail")),
    telefone: yup.string().required(mensagemObrigatorio("telefone")),
    mensagem: yup.string(),
  });

  const { handleSubmit, formState, control } = useForm({
    defaultValues: {
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
    },
    resolver: yupResolver(schemaProduto),
  });

  const enviarMsgWpp = (dados) => {
    const { nome, telefone, email, mensagem } = dados;
    const mensagemWpp = `*Me chamo:*%0A${encodeURIComponent(
      nome,
    )}%0A*Telefone:*%0A${encodeURIComponent(
      telefone,
    )}%0A*E-mail:*%0A${encodeURIComponent(email)}%0A${encodeURIComponent(
      mensagem,
    )}`;
    const url = `${urlWpp}${mensagemWpp}`;
    window.open(url, "_blank");
  };

  const apiSolicitacao = useApiSolicitacaoOrcamento();
  const enviarSolicitacaoOrcamento = (dados) => {
    if (!dados.mensagem) {
      dados.mensagem = `Olá, tudo bem? Tenho interesse em saber mais informações sobre a ${produto.name.toUpperCase()}, Obrigado(a)!`;
    }
    enviarMsgWpp(dados);
    dados.produto = { _id: produtoId };
    apiSolicitacao.cadastrar(dados);
    gtag_report_conversion();
    callLeadFacebook();
  };
  return {
    produto,
    voltar,
    midiaPrincipal: midiaPrincipal.informadoPeloUsuario
      ? retornaMidiaPrincipalProduto(produto)
      : midiaPrincipal,
    listaImagens: possuiListaImagem
      ? listaImagens
      : recuperaListaImagensProduto(produto),
    aoClicaNaImagem,
    exibeDetalhesProduto,
    exibeOrcamento,
    aoClicarEmSolicitarOrcamento,
    exibeBotaoSolicitarOrcamento,
    handleSubmit,
    enviarSolicitacaoOrcamento,
    errors: formState.errors,
    control,
    carregando,
    recuperaListaImagensProduto,
    listaImagemInicial,
  };
};
