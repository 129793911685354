import React from "react";
import { ToastContainer } from "react-toastify";
import { Col, Container, Input, Row } from "reactstrap";
import { useProdutos } from "../../hooks/useProdutos";
import ModalCarregando from "../../component/ModalCarregando/ModalCarregando";
import ListaPalavras from "../../component/ListaPalavras/ListaPalavras";
import CardProduto from "../../component/CardProduto/CardProduto";
import { useMobile } from "../../hooks/useMobile";
import "./produtos.css";

function Produtos() {
  const {
    carregando,
    qtdeProdutos,
    nome,
    setNome,
    palavras,
    selecionarCateoria,
    camposOrdenacao,
    ordenacao,
    selecionarOrdenacao,
    categoriaSelecionada,
    selecionarProduto,
    produtos,
  } = useProdutos({ usaCategoriaComoFiltro: true });

  const { isMobile } = useMobile();

  return (
    <div className="container-fluid">
      <Row>
        <Container fluid>
          <Row>
            <Col lg={12} id="produtos">
              <div className="mb-4">
                <div className="linha" />
                <div className="form-group ">
                  <ListaPalavras
                    palavras={palavras}
                    palavraMobile="Categorias"
                    aoClicar={(categoria) => selecionarCateoria(categoria)}
                    palavraSelecionada={categoriaSelecionada}
                  />
                </div>
                <div className="linha" />
                {!isMobile && (
                  <div className="filtros-prod">
                    <div className="form-group container-fluid d-flex row row-cols-1 row-cols-sm-6 my-2 my-lg-0 align-content-center">
                      <label className="col col-sm-2 justify-content-sm-center">
                        Filtrar por nome:
                      </label>
                      <input
                        type="text"
                        className="col col-sm-4 mb-2 mb-lg-0"
                        placeholder="Pesquisar produto"
                        value={nome}
                        onChange={(e) => {
                          setNome(e.target.value);
                        }}
                      />
                      <label className="col col-sm-2 justify-content-sm-center">
                        Ordenar por:
                      </label>
                      <div className="col col-sm-4 mb-2 mb-lg-0">
                        <Input
                          type="select"
                          value={ordenacao ?? ""}
                          onChange={(e) => {
                            selecionarOrdenacao(e.target.value);
                          }}
                        >
                          <option value="" />
                          {camposOrdenacao.map((item) => (
                            <option key={item.valor} value={item.valor}>
                              {item.label}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <div className="linha" />
                  </div>
                )}
              </div>
              {categoriaSelecionada && (
                <div className="altura-ate-cartas-produto d-flex justify-content-center align-items-center">
                  <p>{categoriaSelecionada}</p>
                </div>
              )}
              <div className="mb-4">
                {qtdeProdutos > 0 ? (
                  <Row>
                    {produtos.map((product, index) => (
                      <Col key={`colunaProduto${index}`} lg={4}>
                        <CardProduto
                          produto={product}
                          key={`itemProduto${index}`}
                          aoClicar={(produto) => selecionarProduto(produto)}
                        />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Row>
                    <div className="text-center mt-5">
                      <img
                        src={require("../../assets/images/empty-search.jpg")}
                        className="img-fluid mb-4"
                      />
                      <h3>
                        Desculpe não encontramos nenhum produto com essas
                        palavras.{" "}
                      </h3>
                      <p>Por favor, tente novamente com outras palavras.</p>
                    </div>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
      <ToastContainer />
      <ModalCarregando carregando={carregando} />
    </div>
  );
}

export default Produtos;
