import { useQuery } from "react-query";
import { querysDesigners } from "../Util/NomesQuerysDesigners";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDesigners } from "./useDesigners";
import { useProdutos } from "./useProdutos";
import { useDetalheProduto } from "./useDetalheProduto";

export const useDesigner = () => {
  const { isLoading, designers } = useDesigners();
  const designerInicial = {
    name: "",
    description: "",
    pictures: [{ url: "" }],
  };

  const [searchParams] = useSearchParams();
  const id = searchParams.get("designerId");

  const filtrarPorId = () => {
    if (designers && id) {
      let designerFiltrados = [...designers];
      designerFiltrados = designerFiltrados.filter(
        (desginer) => desginer._id === id,
      );
      if (designerFiltrados && designerFiltrados.length > 0) {
        return designerFiltrados[0];
      }
    }
    return designerInicial;
  };

  const { data: designer } = useQuery({
    queryFn: () => filtrarPorId(),
    enabled: !!designers && !!id,
    queryKey: [querysDesigners.DESIGNER_ID, id],
  });

  const navigate = useNavigate();

  const voltar = () => {
    navigate("/designers");
  };

  const selecionarProduto = (imagem) => {
    const url = `/detalheProduto?produtoId=${imagem.produtoId}&origem=designer&designerId=${id}`;
    navigate(url);
  };

  const { produtos, carregando } = useProdutos({
    usaCategoriaComoFiltro: false,
  });

  const podeFiltrarProdutos = !!produtos && !!id && produtos.length > 0;

  const filtrarProdutosDesigner = () => {
    if (podeFiltrarProdutos) {
      return produtos.filter(
        (prod) => prod.designer && prod.designer._id === id,
      );
    }
    return produtos;
  };

  const { data: produtosDesigner } = useQuery({
    queryFn: () => filtrarProdutosDesigner(),
    queryKey: [querysDesigners.PRODUTOS_DESIGNER, id],
    enabled: podeFiltrarProdutos,
  });

  const { recuperaListaImagensProduto, listaImagemInicial } =
    useDetalheProduto();

  const podeRecuperarImagensProduto =
    !!produtosDesigner && !!id && produtosDesigner.length > 0;

  const recuperarImagensProdutosDesigner = () => {
    if (podeRecuperarImagensProduto) {
      const imagens = produtosDesigner.flatMap((produto) =>
        recuperaListaImagensProduto(produto).map((imagem) => {
          return { ...imagem, produtoId: produto._id };
        }),
      );
      return imagens.filter((imagem) => imagem.tipo != "video");
    }
    return listaImagemInicial;
  };

  const { data: imagensProduto } = useQuery({
    queryFn: () => recuperarImagensProdutosDesigner(),
    queryKey: [querysDesigners.IMAGENS_PRODUTOS_DESIGNER, id],
    enabled: podeRecuperarImagensProduto,
  });

  const imagensTratadas = imagensProduto ?? listaImagemInicial;
  const possuiImagens =
    imagensTratadas.length > 0 && imagensTratadas[0].src ? true : false;
  return {
    carregando: isLoading || carregando,
    designer: designer ?? designerInicial,
    voltar,
    imagensProduto: imagensTratadas,
    possuiImagens,
    selecionarProduto,
  };
};
