import React, { useEffect, useRef } from "react";
import styles from "./sidebar.module.scss";
import IconeMenu from "../component/IconeMenu/IconeMenu";
import images from "../images";

export default function Sidebar(props) {
  const { children, isOpen, onClose, direction, contentClassName } = props;
  const containerRef = useRef(null);
  const dir = getDirection(direction);

  useEffect(() => {
    const container = containerRef.current;

    function closeOnEscKey(e) {
      if (e.key === "Escape") onClose();
    }

    function closeOnBackdropClick(e) {
      if (e.target === e.currentTarget) onClose();
    }

    if (isOpen) {
      container.addEventListener("click", closeOnBackdropClick);
      document.addEventListener("keyup", closeOnEscKey);
    }

    return () => {
      container.removeEventListener("click", closeOnBackdropClick);
      document.removeEventListener("keyup", closeOnEscKey);
    };
  }, [onClose, isOpen]);

  return (
    <>
      <div
        ref={containerRef}
        className={styles.container}
        data-is-open={isOpen}
      >
        <div className={`${styles.content} ${dir} ${contentClassName ?? ""}`}>
          <div className={styles.bordaredonda}></div>
          <div className={styles.iconeMenu}>
            <IconeMenu aoClicar={() => onClose()} transparente />
          </div>
          {children}
          <div className={styles.rodape}>
            <img src={images.logoInicio} alt="Logo da voktum" />
            <div>VOKTUM 2024, todos direitos reservados</div>
          </div>
        </div>
      </div>
    </>
  );
}

function getDirection(dir) {
  switch (dir) {
    case "right":
      return styles.right;
    default:
      return styles.left;
  }
}
