import { useQuery } from "react-query";
import { querysDesigners } from "../Util/NomesQuerysDesigners";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDesigners } from "./useDesigners";

export const useDesignersPorLetra = () => {
  const { isLoading, imagensOrdenadaNomeDesigner } = useDesigners();

  const [searchParams] = useSearchParams();
  const primeiraLetra = searchParams.get("primeiraLetra");

  const filtrarPelaPrimeiraLetra = () => {
    if (imagensOrdenadaNomeDesigner && primeiraLetra) {
      const imagensPrimeiraLetra = [...imagensOrdenadaNomeDesigner];
      return imagensPrimeiraLetra.filter((imagem) => {
        const letraDesigner = imagem.designer.name.charAt(0).toUpperCase();
        if (letraDesigner === primeiraLetra) {
          return imagem;
        }
      });
    }
    return imagensOrdenadaNomeDesigner;
  };

  const { data: imagensPorPrimeiraLetraDesigner } = useQuery({
    queryFn: () => filtrarPelaPrimeiraLetra(),
    enabled: !!imagensOrdenadaNomeDesigner,
    queryKey: [querysDesigners.IMAGENS_ORDENADAS_NOME_DESIGNER, primeiraLetra],
  });

  const navigate = useNavigate();

  const voltar = () => {
    navigate("/designers");
  };

  const selecionarDesigner = (imagem) => {
    navigate(`/designer?designerId=${imagem.designer._id}`);
  };

  return {
    carregando: isLoading,
    imagensPorPrimeiraLetraDesigner: imagensPorPrimeiraLetraDesigner ?? [],
    voltar,
    selecionarDesigner,
  };
};
