import bag from "./bag-icon.svg";
import voktum from "./logo.png";
import voktumAlternativo from "./logo_alternativa.png";
import door from "./door-icon.svg";
import heart from "./heart-icon.svg";
import menu from "./menu-icon.svg";
import close from "./close-icon.svg";
import search from "./search-icon.svg";
import closeBlack from "./close-icon-black.svg";
import userCircle from "./user-circle.svg";
import iconeMenu from "./icone-menu.svg";
import logoInicio from "./logo_inicio_voktum.png";
import whatsapp from "./whatssapp_preto2.png";
import tituloDesigners from "./titulo_designers.svg";
import tituloProdutos from "./titulo_produtos.svg";
import areaExterna from "./area_externa.jpg";
import areaInterna from "./area_interna.png";

const images = {
  voktumAlternativo: voktumAlternativo,
  voktum: voktum,
  door: door,
  heart: heart,
  bag: bag,
  menu: menu,
  close: close,
  search: search,
  closeBlack: closeBlack,
  userCircle: userCircle,
  iconeMenu: iconeMenu,
  logoInicio: logoInicio,
  whatsapp: whatsapp,
  tituloDesigners: tituloDesigners,
  tituloProdutos: tituloProdutos,
  areaExterna: areaExterna,
  areaInterna: areaInterna,
};

export default images;
