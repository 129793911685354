import React, { useEffect, useRef, useState } from "react";
import { PlayFill } from "react-bootstrap-icons";
import "./VideoDesabilitado.css";
import { useMobile } from "../../hooks/useMobile";

const VideoDesabilitado = ({
  src,
  width = "100%",
  className,
  loading = "lazy",
  tamanhoDoPlayArg = 150,
  aoCarregar = () => {},
}) => {
  const classeUsada = `video-container ${className}`;
  const [tamanhoDoPlay, setTamanhoDoPlay] = useState(tamanhoDoPlayArg);
  const { isMobile } = useMobile();
  useEffect(() => {
    if (isMobile) {
      setTamanhoDoPlay(50);
    }
  }, [isMobile]);
  return (
    <div className={classeUsada}>
      <video
        width={width}
        className={className}
        src={src}
        onLoadedData={() => aoCarregar()}
        loading={loading}
        style={{ display: "block" }}
      />
      <div className="video-overlay">
        <PlayFill size={tamanhoDoPlay} color="white" />
      </div>
    </div>
  );
};

export default VideoDesabilitado;
