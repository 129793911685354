import { memo, useState } from "react";
import SpinnerCarregando from "../SpinnerCarregando/SpinnerCarregando";
import VideoDesabilitado from "./VideoDesabilitado";

const VideoOuCarregando = ({
  urlImagem,
  altura,
  largura,
  alt = "",
  className = "",
  desabilitado = false,
  tamanhoDoPlay,
}) => {
  const [exibeImgCarregando, setExibeImgCarregando] = useState(true);
  const imgCarregando = <SpinnerCarregando altura={altura} largura={largura} />;
  return (
    <>
      {!desabilitado && (
        <video
          alt={alt}
          className={className}
          src={urlImagem}
          onLoadedData={() => setExibeImgCarregando(false)}
          loading="lazy"
          controls
        />
      )}
      {desabilitado && (
        <VideoDesabilitado
          alt={alt}
          className={className}
          src={urlImagem}
          aoCarregar={() => setExibeImgCarregando(false)}
          loading="lazy"
          tamanhoDoPlay={tamanhoDoPlay}
        />
      )}
      {exibeImgCarregando && imgCarregando}
    </>
  );
};

export default memo(VideoOuCarregando);
