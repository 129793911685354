export const apiURL = (() => {
  if (process.env.NODE_ENV === "development") {
    return "http://127.0.0.1:5001/voktum-api/us-central1/app";
  }

  if (process.env.REACT_APP_STAGE === "homologation") {
    return process.env.REACT_APP_API_URL_DEV;
  } else {
    return process.env.REACT_APP_API_URL;
  }
})();
