export const retiraAcentos = (texto) => {
  if (!texto) return;
  return texto
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .split(" ")
    .join("-")
    .toLowerCase();
};

export const retiraAcentosMinusculo = (texto) => {
  if (!texto) return;
  return texto
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};
