import { memo } from "react";
import { useDetalheProduto } from "../../hooks/useDetalheProduto";
import styles from "./DetalheProduto.module.scss";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import ListaImagens from "../../component/ListaImagens/ListaImagens";
import { InputControlado } from "../../component/InputControlado/InputControlado";
import { useMobile } from "../../hooks/useMobile";
import ImagemOuCarregando from "../../component/ImagemOuCarregando/ImagemOuCarregando";
import ModalCarregando from "../../component/ModalCarregando/ModalCarregando";
import VideoOuCarregando from "../../component/VideoOuCarregando/VideoOuCarregando";

const DetalheProduto = () => {
  const {
    produto,
    voltar,
    midiaPrincipal,
    listaImagens,
    aoClicaNaImagem,
    exibeOrcamento,
    exibeDetalhesProduto,
    aoClicarEmSolicitarOrcamento,
    exibeBotaoSolicitarOrcamento,
    handleSubmit,
    enviarSolicitacaoOrcamento,
    control,
    carregando,
  } = useDetalheProduto();

  const { isMobile } = useMobile();
  const classeContainer = `container-fluid position-relative ${styles.container}`;
  const detalhesProduto = (
    <div>
      <Row>
        <span>{produto.description}</span>
      </Row>
    </div>
  );

  const listaImagensProduto = (
    <>
      {!isMobile && (
        <Row className={styles.listaImagens}>
          <ListaImagens imagens={listaImagens} aoClicar={aoClicaNaImagem} />
        </Row>
      )}
    </>
  );

  const formOrcamento = (
    <Row className={styles.containerOrcamento}>
      <Card className={styles.cardOrcamento}>
        <CardBody className={styles.corpoFormOrcamento}>
          <CardTitle className="mt-2">
            <span>SOLICITAÇÃO DE ORÇAMENTO</span>
          </CardTitle>
          <form
            onSubmit={handleSubmit(enviarSolicitacaoOrcamento)}
            className={styles.formOrcamento}
          >
            <InputControlado
              nome="nome"
              control={control}
              tipo="text"
              placeholder="Seu nome"
            />
            <InputControlado
              nome="email"
              control={control}
              tipo="text"
              placeholder="Seu e-mail"
            />
            <InputControlado
              nome="telefone"
              control={control}
              tipo="text"
              placeholder="Seu telefone"
            />
            <InputControlado
              control={control}
              nome="mensagem"
              tipo="textarea"
              linhas={3}
              placeholder={`Olá, tudo bem? Tenho interesse em saber mais informações sobre a ${produto.name.toUpperCase()}, Obrigado(a)!`}
            />
            <button>ENVIAR</button>
          </form>
        </CardBody>
      </Card>
    </Row>
  );

  const btnOrcamento = (
    <Row>
      <div
        className={styles.btnOrcamento}
        onClick={aoClicarEmSolicitarOrcamento}
      >
        <ChevronRight />
        <span>Solicite seu orçamento</span>
      </div>
    </Row>
  );

  return (
    <div className={classeContainer}>
      {!isMobile && (
        <div className={styles.btnVoltar} onClick={() => voltar()}>
          <ChevronLeft />
          <span>Voltar</span>
        </div>
      )}
      <div className={styles.conteudo}>
        <Container fluid>
          <Row className={styles.linhaProduto}>
            <Col className={styles.imgPrincipal}>
              {isMobile && <div className="linha mb-5" />}
              <div className={styles.containerImagem}>
                {midiaPrincipal.tipo === "imagem" && (
                  <ImagemOuCarregando urlImagem={midiaPrincipal.url} />
                )}
                {midiaPrincipal.tipo === "video" && (
                  <VideoOuCarregando urlImagem={midiaPrincipal.url} />
                )}
              </div>
            </Col>
            {isMobile && (
              <Row className={styles.listaImagens}>
                <div className="linha my-3" />
                <ListaImagens
                  imagens={listaImagens}
                  aoClicar={aoClicaNaImagem}
                />
              </Row>
            )}
            <Col className={styles.dadosProduto}>
              <Row>
                <h2>{produto.name}</h2>
              </Row>
              <Row>
                <h3>{produto.designer ? produto.designer.name : ""}</h3>
              </Row>
              {exibeDetalhesProduto && detalhesProduto}
              {exibeBotaoSolicitarOrcamento && btnOrcamento}
              {exibeDetalhesProduto && listaImagensProduto}
              {exibeOrcamento && formOrcamento}
            </Col>
          </Row>
        </Container>
      </div>
      <ModalCarregando carregando={carregando} />
    </div>
  );
};

export default memo(DetalheProduto);
