import Header from "../components/header";
import React, { useEffect } from "react";
// eslint-disable-next-line
import styles from "./defaultLayout.module.scss";
import { useLocation } from "react-router-dom";
import Footer from "./footer/Footer";

const DefaultLayout = ({
  authOnly,
  children,
  titulo,
  estilo = "branco",
  exibeTitulo = true,
  exibeBusca = true,
  urlTitulo = "/",
  logoBranca = false,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    document
      .getElementById("topo")
      .scrollIntoView({ block: "center", behavior: "instant" });
  }, [pathname]);

  return (
    <div className={styles.container} data-estilo={estilo}>
      <Header
        titulo={titulo}
        estilo={estilo}
        exibeBusca={exibeBusca}
        exibeTitulo={exibeTitulo}
        urlTitulo={urlTitulo}
        logoBranca={logoBranca}
      />
      <div className={styles.defContainer}>
        <div className={styles.conteudo}>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
