import { useState } from "react";

const usePaginacaoLista = (itens, itensPorPagina = 12) => {
  const [paginaAtual, setPaginaAtual] = useState(1);

  const ultimoIndice = paginaAtual * itensPorPagina;
  const primeiroIndice = ultimoIndice - itensPorPagina;
  const itensAtuais = itens.slice(primeiroIndice, ultimoIndice);

  const totalPaginas = Math.ceil(itens.length / itensPorPagina);

  const proximaPagina = () => {
    if (existeProximaPagina) {
      setPaginaAtual(paginaAtual + 1);
    }
  };

  const paginaAnterior = () => {
    if (existePaginaAnterior) {
      setPaginaAtual(paginaAtual - 1);
    }
  };

  const existeProximaPagina = paginaAtual < totalPaginas;

  const existePaginaAnterior = paginaAtual > 1;

  const irParaPagina = (pagina) => {
    if (pagina <= totalPaginas && pagina >= 1) {
      setPaginaAtual(pagina);
    }
  };

  const zerarPagina = () => {
    setPaginaAtual(1);
  };

  return {
    itensAtuais,
    paginaAtual,
    totalPaginas,
    proximaPagina,
    paginaAnterior,
    itensPorPagina,
    existePaginaAnterior,
    existeProximaPagina,
    irParaPagina,
    zerarPagina,
  };
};

export default usePaginacaoLista;
