import { useMemo } from "react";

export const useMobile = () => {
  const isMobile = useMemo(() => {
    const media = window.matchMedia("(max-width: 768px)");
    return media.matches;
  }, []);
  return {
    isMobile,
  };
};
