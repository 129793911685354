import { Col, Container, Row } from "reactstrap";
import React from "react";

/**
 * Component for seach itens will be show in list
 * @param valueSearch value in the text box
 * @param placeholder text will be shown in placeholder
 * @param onChangeValue function will be called when text has changed
 * @param itensMatch itens match with text
 * @param onClickItem function will be called when user clicked in item
 * @param listResult child of itensMach when shown a custom component
 * @param onClickSearch function will be called when user cliked in button search
 * @param onClickCancel function will be called when user cliked in button cancel
 * @param onPressEnter function will be called when user press in enter
 * @returns
 */
const SearchBox = ({
  valueSearch,
  placeholder,
  onChangeValue = () => {},
  itensMatch,
  onClickItem = () => {},
  listResult,
  onClickSearch = () => {},
  onClickCancel = () => {},
  onPressEnter = () => {},
  className = "",
  exibeItens = false,
}) => {
  let timeout = null;

  const changeText = (text) => {
    // Clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than <MILLISECONDS>
    clearTimeout(timeout);

    // Make a new timeout set to go off in 1000ms (1 second)
    timeout = setTimeout(onChangeValue(text), 1000);
  };

  const List = ({ itens }) => {
    return listResult ? (
      listResult
    ) : (
      <div className="autocomplete-items">
        {itens.map((item) => (
          <div
            key={item._id}
            onClick={() => {
              onClickItem(item);
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
    );
  };

  const keyPress = (key) => {
    if (key === "Enter") {
      onPressEnter();
    }
  };

  return (
    <Container className={className}>
      <Row>
        <Col lg={12}>
          <div>
            <div className="autocomplete">
              <input
                type="text"
                className="form-control input-text-search"
                placeholder={placeholder}
                value={valueSearch}
                onChange={(e) => changeText(e.target.value)}
                onKeyDown={(e) => keyPress(e.key)}
              />
              <button
                type="button"
                className="searchButton"
                onClick={onClickSearch}
              >
                <i className="fa fa-search"></i>
              </button>
              {valueSearch.length > 1 && (
                <div className="searchCancelButton" onClick={onClickCancel}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              {exibeItens && <List itens={itensMatch} />}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchBox;
