import React, { useCallback, useEffect } from "react";
import styles from "./ListaImagensComFlecha.modules.scss";
import useEmblaCarousel from "embla-carousel-react";
import ImagemOuCarregando from "../ImagemOuCarregando/ImagemOuCarregando";

const ListaImagensComFlecha = ({
  imagens = [{ src: "", tipo: "imagem" | "video", desabilitado: false }],
  aoClicar = (imagem) => {},
  opcoes = { align: "start" },
  classeImagem = "embla_flecha__slide",
  insereConteudoNaImagem = (imagem) => {},
  conteudoSomenteNoHover = (imagem) => {},
  efeitoHover = null,
  efeitoHoverNaImagem = null | "preto",
}) => {
  const determinarEstiloPelaQtdeImagens = useCallback(() => {
    const qtdeImagens = imagens.length;
    if (qtdeImagens < 4 && qtdeImagens > 0) {
      // Seleciona o elemento com a classe 'embla_flecha'
      const emblaFlecha = document.querySelector(".embla_flecha");
      if (emblaFlecha) {
        const estilo = emblaFlecha.style;
        if (qtdeImagens < 4) {
          const curva = qtdeImagens * 10;
          estilo.setProperty("--slide-curva", `${curva}%`);
        }
        if (qtdeImagens < 3) {
          estilo.setProperty("--slide-margem-direita-primeiro", 0);
        }
        if (qtdeImagens == 3) {
          estilo.setProperty("--slide-margem-direita", "-5%");
        }
        const tamanhoSlide = `calc(100% / ${qtdeImagens})`;
        estilo.setProperty("--slide-size-lg", tamanhoSlide);
      }
    }
  }, [imagens]);

  useEffect(() => {
    determinarEstiloPelaQtdeImagens();
  }, [determinarEstiloPelaQtdeImagens]);

  const classeEfeito = efeitoHover ?? "embla_flecha__slide_hover";

  const classeEfeitoImagem = efeitoHoverNaImagem === "preto" ? "_preto" : "";

  const [emblaRef] = useEmblaCarousel(opcoes);
  const tamanhoLista = imagens.length - 1;

  const classePrimeiraImagem = `embla_flecha__slide__number-primeiro${classeEfeitoImagem}`;
  const classeNormal = `embla_flecha__slide__number${classeEfeitoImagem}`;
  const classeUltimaImagem = `embla_flecha__slide__number-ultimo${classeEfeitoImagem}`;

  const retornaClasseFlecha = (index) => {
    if (index === 0) {
      return classePrimeiraImagem;
    } else if (index == tamanhoLista) {
      return classeUltimaImagem;
    }
    return classeNormal;
  };

  return (
    <section className="embla_flecha">
      <div className="embla_flecha__viewport" ref={emblaRef}>
        <div className="embla_flecha__container">
          {imagens.map((imagem, index) => {
            const classeFlecha = retornaClasseFlecha(index);
            return (
              <div
                className={`${classeImagem} ${classeEfeito}`}
                key={index}
                onClick={() => aoClicar(imagem)}
              >
                <div className={classeFlecha}>
                  {insereConteudoNaImagem(imagem)}
                  <divEspecial className="divEspecial">
                    {conteudoSomenteNoHover(imagem)}
                  </divEspecial>
                  <ImagemOuCarregando urlImagem={imagem.src} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ListaImagensComFlecha;
