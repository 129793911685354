import { useState } from "react";
import { useApiProduto } from "../apirest/useApiProduto";
import usePaginacaoLista from "./usePaginacaoLista";
import { retiraAcentosMinusculo } from "../Util/RetiraAcentos";
import { useQuery } from "react-query";
import { categoriasProduto } from "../constants/CategoriasProduto";
import { useLocation, useNavigate } from "react-router-dom";
import { querysTelaHeader } from "../Util/NomesQuerysProdutos";

export const useProdutosHeader = () => {
  const [carregando, setCarregando] = useState(false);
  const api = useApiProduto();
  const [nome, setNome] = useState("");
  const nomeQuerysProduto = querysTelaHeader;

  const carregarProdutos = async () => {
    setCarregando(true);
    try {
      const { data } = await api.listar();
      if (data) {
        return data.doc;
      }
      return [];
    } catch (error) {
      console.error("Erro ao carregar produtos:", error);
    } finally {
      setCarregando(false);
    }
  };

  const { data: produtos } = useQuery({
    queryFn: async () => await carregarProdutos(),
    queryKey: [nomeQuerysProduto.PRODUTOS],
  });

  const filtrarProdutos = () => {
    let produtosFiltrados = produtos;
    if (nome) {
      const nomeSemAcentos = retiraAcentosMinusculo(nome);
      produtosFiltrados = produtos.filter((produto) => {
        const nomeProdutoSemAcento = retiraAcentosMinusculo(produto.name);
        if (nomeProdutoSemAcento.includes(nomeSemAcentos)) {
          return produto;
        }
      });
    }
    return produtosFiltrados;
  };

  const { data: produtosApi } = useQuery({
    queryFn: () => filtrarProdutos(),
    queryKey: [nomeQuerysProduto.PRODUTOS_API, nome],
    enabled: !!produtos,
  });

  const navigate = useNavigate();

  const selecionarProduto = (produto) => {
    setNome("");
    navigate(`/detalheProduto?produtoId=${produto._id}`);
  };

  return {
    produtos: produtosApi ?? [],
    carregando,
    qtdeProdutos: produtosApi?.length ?? 0,
    nome,
    setNome,
    selecionarProduto,
  };
};
