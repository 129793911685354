import { memo } from "react";
import images from "../../images";

const IconeMenu = ({
  aoClicar = () => {},
  className = "caixa-preta",
  transparente = false,
}) => {
  const estilo = transparente ? { backgroundColor: "transparent" } : {};
  return (
    <div className={className} onClick={() => aoClicar()} style={estilo}>
      <img src={images.iconeMenu} alt="Icone de menu" />
    </div>
  );
};

export default memo(IconeMenu);
