import { memo, useEffect, useState } from "react";
import { Card, CardImgOverlay, CardText } from "reactstrap";
import { useMobile } from "../../hooks/useMobile";
import ImagemOuCarregando from "../ImagemOuCarregando/ImagemOuCarregando";
import styles from "./cardProduto.module.scss";

const CardProduto = ({ produto, aoClicar = (produto) => {} }) => {
  const variant = produto.variants[0];
  const designer = produto.designer;
  const estiloImagem = `rounded-4 ${styles.cardProduto}`;
  const { isMobile } = useMobile();
  const classeRodapeInicial =
    "position-absolute bottom-0 backgroud-rodape-carta-produto w-100 start-0 rounded-bottom-4 text-white";
  const classeRodapeInicialInvisivel = `${classeRodapeInicial} invisible`;
  const [classeRodapeCarta, setClasseRodapeCarta] = useState(
    classeRodapeInicialInvisivel,
  );

  const [alturaImgCarregando, setAlturaImgCarregando] = useState(548);
  const urlImagem = variant.pictures[0].url;

  const mostrarRodape = () => {
    if (!isMobile) {
      setClasseRodapeCarta(classeRodapeInicial);
    }
  };

  const esconderRoodape = () => {
    if (!isMobile) {
      setClasseRodapeCarta(classeRodapeInicialInvisivel);
    }
  };

  useEffect(() => {
    if (isMobile) {
      setClasseRodapeCarta(classeRodapeInicial);
      setAlturaImgCarregando(347);
    }
  }, [isMobile]);

  return (
    <div>
      <Card
        className="position-relative rounded-4 my-2 pointer"
        onMouseEnter={() => mostrarRodape()}
        onMouseLeave={() => esconderRoodape()}
        onClick={() => aoClicar(produto)}
      >
        <ImagemOuCarregando
          altura={alturaImgCarregando}
          alt={`Imagem do produto${produto.name}`}
          urlImagem={urlImagem}
          className={estiloImagem}
        />
        <CardImgOverlay>
          <div className={classeRodapeCarta}>
            <div>
              <CardText className="texto-carta-nome-produto">
                {produto.name}
              </CardText>
              <CardText className="texto-carta-designer-produto">
                {designer ? designer.name : ""}
              </CardText>
            </div>
          </div>
        </CardImgOverlay>
      </Card>
    </div>
  );
};

export default memo(CardProduto);
