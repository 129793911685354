import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import "../src/css/plugins.css";
import "react-toastify/dist/ReactToastify.css";
import DefaultLayout from "./layouts/defaultLayout";
import Produtos from "./paginas/produtos/produtos";
import Inicio from "./paginas/inicio/inicio";
import Designers from "./paginas/designers/designers";
import DetalheProduto from "./paginas/DetalheProduto/DetalheProduto";
import { useMobile } from "./hooks/useMobile";
import { AnimatePresence } from "framer-motion";
import DesignersPorLetra from "./paginas/designersPorLetra/designersPorLetra";
import Designer from "./paginas/designer/designer";
import images from "./images";
import QuemSomos from "./paginas/quemSomos/QuemSomos";
import Contatos from "./paginas/contatos/Contatos";

export default function App() {
  const { isMobile } = useMobile();
  const [estiloDetalheProduto, setEstiloDetalheProduto] = useState("preto");
  const [estiloDesigner, setEstiloDesigner] = useState("transparent");
  useEffect(() => {
    if (isMobile) {
      setEstiloDetalheProduto("branco");
      setEstiloDesigner("pretoTransparente");
    }
  }, [isMobile]);

  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={<></>}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Inicio />} />
            <Route
              exact
              path="/produtos"
              element={
                <DefaultLayout
                  titulo={
                    <img
                      src={images.tituloProdutos}
                      alt="Imagem do título de produtos"
                    />
                  }
                  urlTitulo="/produtos"
                >
                  <Produtos />
                </DefaultLayout>
              }
            />
            <Route
              exact
              path="/designers"
              element={
                <DefaultLayout exibeBusca={false} estilo="transparent">
                  <Designers />
                </DefaultLayout>
              }
            />
            <Route
              exact
              path="/designersPorLetra"
              element={
                <DefaultLayout exibeBusca={false} estilo="transparent">
                  <DesignersPorLetra />
                </DefaultLayout>
              }
            />
            <Route
              exact
              path="/designer"
              element={
                <DefaultLayout exibeBusca={false} estilo={estiloDesigner}>
                  <Designer />
                </DefaultLayout>
              }
            />
            <Route
              exact
              path="/detalheProduto"
              element={
                <DefaultLayout
                  estilo={estiloDetalheProduto}
                  urlTitulo="/produtos"
                >
                  <DetalheProduto />
                </DefaultLayout>
              }
            />
            <Route
              exact
              path="/quemSomos"
              element={
                <DefaultLayout
                  estilo="transparent"
                  exibeBusca={false}
                  logoBranca
                >
                  <QuemSomos />
                </DefaultLayout>
              }
            />
            <Route
              exact
              path="/contatos"
              element={
                <DefaultLayout
                  estilo="transparent"
                  exibeBusca={false}
                  logoBranca
                >
                  <Contatos />
                </DefaultLayout>
              }
            />
          </Routes>
        </Router>
      </Suspense>
    </AnimatePresence>
  );
}
