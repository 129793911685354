import { useMemo } from "react";
import axios from "axios";
import { apiURL } from "./getAPIURL";

export const useApiProduto = () => {
  // Use useMemo para memoizar a instância de api
  const api = useMemo(() => {
    const url = `${apiURL}/produtos`;
    const urlRecuperar = `${url}/findById?`;
    const OPTIONS = {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const listar = async (dados) => {
      const resposta = await axios.get(url, { params: { dados } }, OPTIONS);
      return resposta;
    };
    const cadastrar = async (dados) => {
      const resposta = await axios.post(url, dados, OPTIONS);
      return resposta;
    };
    const alterar = async (dados) => {
      const resposta = await axios.patch(url, dados, OPTIONS);
      return resposta;
    };
    const recuperarPorId = async (id) => {
      const urlId = `${urlRecuperar}idProduct=${id}`;
      const resposta = await axios.get(urlId, OPTIONS);
      return resposta;
    };
    const recuperarPorCodigo = async (codigo) => {
      const urlCodigo = `${urlRecuperar}code=${codigo}`;
      const resposta = await axios.get(urlCodigo, OPTIONS);
      return resposta;
    };
    const deletar = async (id) => {
      const corpo = { source: { idProduct: id } };
      const opcoesDelete = {
        ...OPTIONS,
        data: corpo,
      };
      const resposta = await axios.delete(url, opcoesDelete);
      return resposta;
    };
    return {
      listar,
      cadastrar,
      alterar,
      recuperarPorId,
      recuperarPorCodigo,
      deletar,
    };
  }, []); // Deps do useMemo

  return api;
};
