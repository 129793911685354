import React, { useCallback, useEffect } from "react";
import styles from "./ListaImagensComFlechaVertical.modules.scss";
import useEmblaCarousel from "embla-carousel-react";
import ImagemOuCarregando from "../ImagemOuCarregando/ImagemOuCarregando";

const ListaImagensComFlechaVertical = ({
  imagens = [{ src: "", tipo: "imagem" | "video", desabilitado: false }],
  aoClicar = (imagem) => {},
  opcoes = { align: "start" },
  classeImagem = "embla_flecha_vertical__slide",
  insereConteudoNaImagem = (imagem) => {},
  conteudoSomenteNoHover = (imagem) => {},
  variacaoImagem = null | "preto",
}) => {
  const [emblaRef] = useEmblaCarousel(opcoes);
  const tamanhoLista = imagens.length - 1;

  const determinarEstiloPelaQtdeImagens = useCallback(() => {
    const qtdeImagens = imagens.length;
    if (qtdeImagens < 4 && qtdeImagens > 0) {
      // Seleciona o elemento com a classe 'embla_flecha'
      const emblaFlecha = document.querySelector(".embla_flecha_vertical");
      if (emblaFlecha) {
        const estilo = emblaFlecha.style;
        if (qtdeImagens === 1) {
          estilo.setProperty("--margem-top-primeiro", `0%`);
        }
        const tamanhoSlide = `calc(100% / ${qtdeImagens})`;
        estilo.setProperty("--slide-size", tamanhoSlide);
      }
    }
  }, [imagens]);

  useEffect(() => {
    determinarEstiloPelaQtdeImagens();
  }, [determinarEstiloPelaQtdeImagens]);

  const classeEfeitoImagem = variacaoImagem === "preto" ? "_preto" : "";

  const classePrimeiraImagem = `embla_flecha_vertical__slide__number-primeiro${classeEfeitoImagem}`;
  const classeNormal = `embla_flecha_vertical__slide__number${classeEfeitoImagem}`;
  const classeUltimaImagem = `embla_flecha_vertical__slide__number-ultimo${classeEfeitoImagem}`;

  const retornaClasseFlecha = (index) => {
    if (index === 0) {
      return classePrimeiraImagem;
    } else if (index == tamanhoLista) {
      return classeUltimaImagem;
    }
    return classeNormal;
  };

  return (
    <section className="embla_flecha_vertical">
      <div className="embla_flecha_vertical__viewport" ref={emblaRef}>
        <div className="embla_flecha_vertical__container">
          {imagens.map((imagem, index) => {
            const classeFlecha = retornaClasseFlecha(index);
            return (
              <div
                className={classeImagem}
                key={index}
                onClick={() => aoClicar(imagem)}
              >
                <div className={classeFlecha}>
                  {insereConteudoNaImagem(imagem)}
                  {conteudoSomenteNoHover(imagem)}
                  <ImagemOuCarregando urlImagem={imagem.src} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ListaImagensComFlechaVertical;
