import { memo, useRef, useState } from "react";
import SpinnerCarregando from "../SpinnerCarregando/SpinnerCarregando";

const ImagemOuCarregando = ({
  urlImagem,
  altura,
  largura,
  alt = "",
  className = "",
  aoCarregar = (refImg) => {},
}) => {
  const [exibeImgCarregando, setExibeImgCarregando] = useState(true);
  const imgCarregando = <SpinnerCarregando altura={altura} largura={largura} />;
  const refImg = useRef(null);
  const carregouImagem = () => {
    setExibeImgCarregando(false);
    aoCarregar(refImg.current);
  };
  return (
    <>
      <img
        alt={alt}
        className={className}
        src={urlImagem}
        onLoad={carregouImagem}
        loading="lazy"
        ref={refImg}
      />
      {exibeImgCarregando && imgCarregando}
    </>
  );
};

export default memo(ImagemOuCarregando);
