import { memo } from "react";
import { TailSpin } from "react-loader-spinner";

const SpinnerCarregando = ({
  altura = "50%",
  largura = "50%",
  // cores = ["#6c757d", "#6c757d", "#6c757d", "#6c757d", "#6c757d"],
  cor = "#4fa94d",
}) => {
  return (
    <TailSpin
      visible={true}
      height={altura}
      width={largura}
      ariaLabel="color-ring-loading"
      wrapperStyle={{}}
      wrapperClass="d-flex justify-content-center"
      color={cor}
    />
  );
};

export default memo(SpinnerCarregando);
