import { memo } from "react";
import "./ListaPalavrasMobile.css";
import { ItemPalavraMobile } from "./ItemPalavraMobile";
import { XLg } from "react-bootstrap-icons";
const ListaPalavrasMobile = ({
  palavras = [
    {
      palavra: "",
      valor: "",
      marcada: false,
      tamanho: 10,
      url: "/",
      search: "",
    },
  ],
  aoClicar = () => {},
}) => {
  const palavraMarcada = palavras.find((palavra) => palavra.marcada);
  const fecharLista = () => {
    if (palavraMarcada && palavraMarcada.valor) {
      aoClicar(palavraMarcada.valor);
    } else {
      aoClicar();
    }
  };
  return (
    <div className="embla">
      <XLg size={17} className="imgFecharLista" onClick={fecharLista} />
      <ItemPalavraMobile
        palavras={palavras}
        aoClicar={(valor) => aoClicar(valor)}
      />
    </div>
  );
};
export default memo(ListaPalavrasMobile);
