import { useMemo } from "react";
import { apiURL } from "./getAPIURL";
import axios from "axios";

export const useApiDesigner = () => {
  const api = useMemo(() => {
    const url = `${apiURL}/designers`;
    const urlRecuperar = `${url}/findById?`;
    const OPTIONS = {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const listar = async (dados) => {
      const resposta = await axios.get(url, { params: { dados } }, OPTIONS);
      return resposta;
    };
    const recuperarPorId = async (id) => {
      const urlId = `${urlRecuperar}id=${id}`;
      const resposta = await axios.get(urlId, OPTIONS);
      return resposta;
    };
    return {
      listar,
      recuperarPorId,
    };
  }, []); // Deps do useMemo

  return api;
};
