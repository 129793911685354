import React from "react";
import images from "../../images";
import styles from "./Footer.module.scss";
import { urlWpp } from "../../Util/UrlWhatssapp";

const Footer = () => {
  const abrirWhattsapp = () => {
    const mensagemWpp = "Olá, estou vindo do site!";
    const url = `${urlWpp}${mensagemWpp}`;
    window.open(url, "_blank");
    gtag_report_conversion();
    callLeadFacebook();
  };
  return (
    <div className={styles.whatsapp} onClick={abrirWhattsapp}>
      <img src={images.whatsapp} alt="Imagem do whatsapp" />
    </div>
  );
};

export default React.memo(Footer);
