import React from "react";
import { Controller } from "react-hook-form";
import { Input } from "reactstrap";

export const InputControlado = ({
  nome,
  control,
  tipo = "text" |
    "email" |
    "select" |
    "file" |
    "radio" |
    "checkbox" |
    "switch" |
    "textarea" |
    "button" |
    "reset" |
    "submit" |
    "date" |
    "datetime-local" |
    "hidden" |
    "image" |
    "month" |
    "number" |
    "range" |
    "search" |
    "tel" |
    "url" |
    "week" |
    "password" |
    "datetime" |
    "time" |
    "color",
  outros,
  desabilitado = false,
  classe = "",
  linhas = 0,
  margemNaoErro = false,
  placeholder = "",
}) => {
  const input = (field) => {
    return (
      <Input
        name={nome}
        type={tipo}
        value={field.value ?? ""}
        className={classe}
        disabled={desabilitado}
        rows={linhas}
        placeholder={placeholder}
        {...field}
        {...outros}
      />
    );
  };

  return (
    <Controller
      name={nome}
      control={control}
      render={({ field, fieldState }) => (
        <>
          {margemNaoErro && !fieldState.invalid ? (
            <div className="margemNaoErro">{input(field)}</div>
          ) : (
            input(field)
          )}
          <p className="erro ml-2">{fieldState.error?.message}</p>
        </>
      )}
    />
  );
};
