import { memo } from "react";
import styles from "./designers.module.scss";
import { useMobile } from "../../hooks/useMobile";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import ListaImagensComFlecha from "../../component/ListaImagensComFlecha/ListaImagensComFlecha";
import { useDesigners } from "../../hooks/useDesigners";
import ModalCarregando from "../../component/ModalCarregando/ModalCarregando";
import ListaImagensComFlechaVertical from "../../component/ListaImagensComFlechaVertical/ListaImagensComFlechaVertical";
import { motion } from "framer-motion";
import images from "../../images";

const Designers = () => {
  const classeContainer = `container-fluid ${styles.container}`;
  const { isMobile } = useMobile();
  const {
    carregando,
    imagensOrdenadasComPrimeiraLetra,
    aoClicarImagemDesigner,
    selecionarDesigner,
  } = useDesigners();
  const duracaoMenor = 0.7;
  const visible = { opacity: 1, y: 0, transition: { duration: duracaoMenor } };

  const nomeDesigner = (imagem) => {
    return (
      <div className={styles.divNomeDesigner}>
        <div className={styles.nomeDesigner}>
          <ChevronLeft />
          <span>{imagem.designer.name}</span>
        </div>
      </div>
    );
  };

  const nomeDesignerMobile = (imagem) => {
    return (
      <div className={styles.nomeDesignerMobile}>
        <span>{imagem.designer.name}</span>
      </div>
    );
  };

  const tagTitulo = (
    <motion.div
      className={styles.tagTitulo}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1 } },
      }}
    >
      <img src={images.tituloDesigners} alt="Imagem do título de designers" />
    </motion.div>
  );

  const tituloDesktop = (
    <div className={styles.containerTitulo}>
      {tagTitulo}
      <motion.div
        className={styles.btnPasseMouse}
        variants={{
          hidden: {
            opacity: 0,
            x: 44,
            y: -50,
            transition: { duration: duracaoMenor },
          },
          visible: {
            opacity: 1,
            x: 0,
            y: 0,
            transition: { duration: duracaoMenor },
          },
        }}
      >
        <span>Puxe e arraste o mouse</span>
        <ChevronRight />
      </motion.div>
    </div>
  );

  const tituloMobile = (
    <div className={styles.containerTitulo}>
      {
        <>
          <div className="linha" />
          {tagTitulo}
        </>
      }
    </div>
  );
  const tituloPagina = (
    <>
      {isMobile && tituloMobile}
      {!isMobile && tituloDesktop}
    </>
  );

  const conteudoComFlechasMobile = (
    <div className={styles.conteudoComFlechasMobile}>
      <ListaImagensComFlechaVertical
        imagens={imagensOrdenadasComPrimeiraLetra}
        insereConteudoNaImagem={nomeDesignerMobile}
        opcoes={{ axis: "y", slidesToScroll: "auto" }}
        aoClicar={selecionarDesigner}
        variacaoImagem="preto"
      />
    </div>
  );

  const conteudoComFlechasDesktop = (
    <motion.div
      className={styles.conteudoFlechasDesktop}
      variants={{
        hidden: { opacity: 0, x: -300, transition: { duration: duracaoMenor } },
        visible: { opacity: 1, x: 0, transition: { duration: duracaoMenor } },
      }}
    >
      <ListaImagensComFlecha
        imagens={imagensOrdenadasComPrimeiraLetra}
        conteudoSomenteNoHover={nomeDesigner}
        efeitoHover=""
        efeitoHoverNaImagem="preto"
        aoClicar={selecionarDesigner}
      />
    </motion.div>
  );

  const conteudoComFlechas = (
    <>
      {isMobile && conteudoComFlechasMobile}
      {!isMobile && conteudoComFlechasDesktop}
    </>
  );

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: duracaoMenor } }}
    >
      <motion.div
        className={classeContainer}
        variants={{ hidden: { opacity: 0 }, visible }}
      >
        {tituloPagina}
        {conteudoComFlechas}
        <ModalCarregando carregando={carregando} />
      </motion.div>
    </motion.article>
  );
};

export default memo(Designers);
