import { memo, useCallback, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import styles from "./ListaPalavras.module.scss";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import ListaPalavrasMobile from "../ListaPalavrasMobile/ListaPalavrasMobile";
const ListaPalavras = ({
  palavras = [
    {
      palavra: "",
      valor: "",
      marcada: false,
      tamanho: 10,
      url: "/",
      search: "",
    },
  ],
  palavraMobile = "",
  palavraSelecionada = "",
  aoClicar = (valor) => {},
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [modalAberto, setModalAberto] = useState(false);
  const palavraExibidaMobile = palavraSelecionada
    ? palavraSelecionada
    : palavraMobile;

  const slides = palavras.map((palavra, index) => {
    const ultimo = index === palavras.length - 1;
    const estiloPalavra = palavra.marcada ? styles.selecionado : styles.normal;
    // const flex = { flex: `0 0 ${palavra.tamanho}` };
    const flex = { flex: `0 0 5%` };
    return (
      <div
        className={styles.embla__slide}
        key={`slide${palavra.valor}`}
        style={flex}
        onClick={() => clicar(palavra.valor)}
      >
        <div className={estiloPalavra}>{palavra.palavra}</div>
        {!ultimo && <div className={styles.traco}> — </div>}
      </div>
    );
  });
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  const slide = (
    <div className={styles.embla}>
      <div className={styles.embla__viewport} ref={emblaRef}>
        <div className={styles.embla__container}>{slides}</div>
      </div>
      {/* <button className={styles.embla__prev} onClick={scrollPrev}>
      Prev
    </button>
    <button className={styles.embla__next} onClick={scrollNext}>
      Next
    </button> */}
    </div>
  );

  const clicar = (valor) => {
    setModalAberto(false);
    aoClicar(valor);
  };

  return (
    <div className="w-100 h-100 container-fluid flex align-items-center">
      <div className={styles.slideDesktop}>{slide}</div>
      <div className={styles.slideMobile} onClick={() => setModalAberto(true)}>
        {palavraExibidaMobile}
      </div>
      <Modal
        isOpen={modalAberto}
        toggle={() => clicar()}
        className="opacity-75"
      >
        <ModalBody className="min-vh-100">
          <ListaPalavrasMobile
            palavras={palavras}
            aoClicar={(valor) => clicar(valor)}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};
export default memo(ListaPalavras);
