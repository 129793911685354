export const querysDesigners = {
  DESGINERS: "designers",
  IMAGENS: "imagens",
  IMAGENS_ORDENADAS_NOME_DESIGNER: "imagensOrdenadasNomeDesigner",
  IMAGENS_ORDENADAS_NOME_DESIGNER_PRIMEIRA_LETRA_DESIGNER:
    "imagensOrdenadasNomeDesignerPrimeiraLetraDesigner",
  DESIGNER_ID: "designerId",
  PRODUTOS_DESIGNER: "produtosDesigner",
  IMAGENS_PRODUTOS_DESIGNER: "imagensProdutosDesigner",
};
