import React, { useMemo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDisclosure } from "../hooks/useDisclosure";
import images from "../images";
import styles from "./header.module.scss";
import Sidebar from "./sidebar";
import SearchBox from "../component/SearchBox/SearchBox";
import IconeMenu from "../component/IconeMenu/IconeMenu";
import { Search, XLg } from "react-bootstrap-icons";
import { useProdutosHeader } from "../hooks/useProdutosHeader";

const pages = [
  {
    title: "Home",
    path: "/",
    private: false,
    mobile: true,
    desktop: true,
    findText: null,
  },
  {
    title: "Designers",
    path: "/designers",
    private: false,
    mobile: true,
    desktop: true,
    findText: null,
  },
  {
    title: "Produtos",
    path: "/produtos",
    private: false,
    mobile: true,
    desktop: true,
    findText: null,
  },
  {
    title: "Quem somos",
    path: "/quemSomos",
    private: false,
    mobile: true,
    desktop: true,
    findText: null,
  },
  {
    title: "Contatos",
    path: "/contatos",
    private: false,
    mobile: true,
    desktop: true,
    findText: null,
  },
];

const direcao = "right";

export default function Header({
  titulo,
  estilo = "branco",
  exibeTitulo = true,
  exibeBusca = true,
  urlTitulo,
  logoBranca = false,
}) {
  const { isOpen, onToggle, onClose } = useDisclosure(false);
  const [habilitarBusca, setHabilitarBusca] = useState(true);
  const history = useNavigate();
  const isMobile = useMemo(() => {
    const media = window.matchMedia("(max-width: 768px)");
    return media.matches;
  }, []);

  function toggleBag() {
    const event = new CustomEvent("togglebag");
    document.dispatchEvent(event);
  }

  function search() {
    const event = new CustomEvent("search");
    document.dispatchEvent(event);
  }

  const { setNome, produtos, nome, selecionarProduto } = useProdutosHeader();

  const digitouNome = nome && nome.length > 0;

  const navDesktop = useMemo(() => {
    return (
      <nav>
        {pages.map(
          (page) =>
            page.desktop && (
              <li key={page.path}>
                <Link
                  to={{
                    pathname: page.path,
                    category: page.category,
                    findText: page.findText,
                    restart: page.restart,
                    sale: page.sale,
                    age: page.age,
                    elasusam: page.elasusam,
                  }}
                  onClick={onClose}
                >
                  {page.title}
                </Link>
              </li>
            ),
        )}
      </nav>
    );
  }, [onClose]);

  const navMobile = useMemo(() => {
    return (
      <nav>
        {pages.map(
          (page) =>
            !page.private &&
            page.mobile && (
              <li key={page.path}>
                <Link
                  to={{
                    pathname: page.path,
                    category: page.category,
                    findText: page.findText,
                    restart: page.restart,
                    sale: page.sale,
                    age: page.age,
                  }}
                  onClick={onClose}
                >
                  {page.title}
                </Link>
              </li>
            ),
        )}
      </nav>
    );
  }, [onClose]);

  const navMobilePrivate = useMemo(() => {
    return (
      <nav>
        {pages.map(
          (page) =>
            page.mobile && (
              <li key={page.path}>
                <Link
                  to={{
                    pathname: page.path,
                    category: page.category,
                    findText: page.findText,
                    restart: page.restart,
                    sale: page.sale,
                    age: page.age,
                  }}
                  onClick={onClose}
                >
                  {page.title}
                </Link>
              </li>
            ),
        )}
      </nav>
    );
  }, [onClose]);

  const aoSelecionarProduto = (produto) => {
    // setHabilitarBusca(false);
    selecionarProduto(produto);
  };

  function renderMenu() {
    let sidebar;
    if (isMobile) {
      sidebar = (
        <Sidebar isOpen={isOpen} onClose={onClose} direction={direcao}>
          {navMobile}
        </Sidebar>
      );
    } else {
      sidebar = (
        <Sidebar isOpen={isOpen} onClose={onClose} direction={direcao}>
          {navDesktop}
        </Sidebar>
      );
    }
    return sidebar;
  }
  const estilosLogoBranca = ["preto", "pretoTransparente"];
  const preto = estilosLogoBranca.includes(estilo) || logoBranca;
  const branco = !estilosLogoBranca.includes(estilo) && !logoBranca;

  return (
    <div className={styles.headerContainer} data-estilo={estilo} id="topo">
      <div className={styles.menu}>{renderMenu()}</div>
      <header>
        <Link to="/" className={styles.logoContainer}>
          {branco && (
            <img src={images.voktumAlternativo} alt="Logo da voktum" />
          )}
          {preto && <img src={images.logoInicio} alt="Logo da voktum" />}
        </Link>
        <div className={styles.fundoBranco}>
          <div className={styles.titulo}>
            {exibeBusca && habilitarBusca && (
              <div className={styles.actionsMobile}>
                <SearchBox
                  valueSearch={nome}
                  itensMatch={produtos}
                  placeholder="Buscar"
                  exibeItens={digitouNome}
                  onChangeValue={setNome}
                  onClickCancel={() => setNome("")}
                  onClickItem={(produto) => aoSelecionarProduto(produto)}
                  className="w-100"
                />
              </div>
            )}
            {exibeTitulo && <Link to={urlTitulo}>{titulo}</Link>}
          </div>

          <div className={styles.actions}>
            {exibeBusca && (
              <>
                <div className={styles.actionsDesktop}>
                  <SearchBox
                    valueSearch={nome}
                    itensMatch={produtos}
                    placeholder="Buscar"
                    exibeItens={digitouNome}
                    onChangeValue={setNome}
                    onClickCancel={() => setNome("")}
                    onClickItem={(produto) => aoSelecionarProduto(produto)}
                  />
                </div>
                {!habilitarBusca && (
                  <div className={styles.actionsMobile}>
                    <Search
                      size={17}
                      className="me-3"
                      onClick={() => setHabilitarBusca(true)}
                    />
                  </div>
                )}
              </>
            )}
            <IconeMenu aoClicar={() => onToggle()} />
          </div>
        </div>
      </header>
    </div>
  );
}
