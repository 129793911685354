import { memo } from "react";
import styles from "./designer.module.scss";
import { useMobile } from "../../hooks/useMobile";
import ModalCarregando from "../../component/ModalCarregando/ModalCarregando";
import { motion } from "framer-motion";
import { useDesigner } from "../../hooks/useDesigner";
import ImagemOuCarregando from "../../component/ImagemOuCarregando/ImagemOuCarregando";
import { ChevronLeft } from "react-bootstrap-icons";
import ListaImagens from "../../component/ListaImagens/ListaImagens";
import { Card } from "reactstrap";

const Designer = () => {
  const classeContainer = `container-fluid ${styles.container}`;
  const { isMobile } = useMobile();
  const {
    carregando,
    designer,
    voltar,
    imagensProduto,
    possuiImagens,
    selecionarProduto,
  } = useDesigner();
  const visible = { opacity: 1, y: 0, transition: { duration: 0.3 } };
  const aoCarregarImagem = (refImagem) => {
    const container = document.querySelector(`.${styles.container}`);
    let posicao = "0 20%";
    if (
      refImagem.naturalWidth > refImagem.width &&
      refImagem.naturalHeight > refImagem.height
    ) {
      posicao = "center";
    }
    container.style.setProperty("--posicaoImagem", posicao);
  };

  const duracaoEfeitoImagem = 0.7;

  const cardImagem = (imagem) => {
    return (
      <Card
        className="position-relative rounded-4 my-5 pointer"
        key={`card-${imagem.src}`}
        onClick={() => selecionarProduto(imagem)}
      >
        <ImagemOuCarregando
          key={`img-${imagem.src}`}
          alt={`Imagem do produto${imagem.src}`}
          urlImagem={imagem.src}
          className="rounded-4"
        />
      </Card>
    );
  };

  const listaImagensMobile = (
    <div className={styles.cartasProduto}>
      {possuiImagens && imagensProduto.map((imagem) => cardImagem(imagem))}
    </div>
  );

  const conteudoMobile = (
    <div className={styles.divDesigner}>
      <div className={styles.divDesinerInterna}>
        <h1>{designer.name}</h1>
        <h4>{designer.description}</h4>
        {listaImagensMobile}
      </div>
    </div>
  );

  const conteudoComFlechasMobile = (
    <motion.div
      className={styles.conteudoComFlechasMobile}
      variants={{
        hidden: {
          opacity: 0,
          transition: { duration: duracaoEfeitoImagem },
        },
        visible: {
          opacity: 1,
          transition: { duration: duracaoEfeitoImagem },
        },
      }}
    >
      <div className={styles.conteudoDesignerMobileInterno}>
        <div className={styles.linhaMobile} />
        <ImagemOuCarregando
          className={styles.imagemDesigner}
          urlImagem={designer.pictures[0].url}
          aoCarregar={aoCarregarImagem}
          alt={`Imagem do designer ${designer.name}`}
        />
        {conteudoMobile}
      </div>
    </motion.div>
  );

  const wrapperVariants = {
    hidden: {
      clipPath: "polygon(0% 0%, 100% 0, 100% 100%, 0% 100%, 0% 50%)",
      transition: { duration: duracaoEfeitoImagem },
    },
    visible: {
      clipPath: "polygon(15% 0%, 100% 0, 100% 100%, 15% 100%, 0% 50%)",
      transition: { duration: duracaoEfeitoImagem },
    },
  };

  const listaImagens = (
    <div className="w-100 mt-5">
      {possuiImagens && (
        <ListaImagens imagens={imagensProduto} aoClicar={selecionarProduto} />
      )}
    </div>
  );
  const conteudoDesigner = (
    <div className={styles.conteudoDesigner}>
      <div className={styles.conteudoDesignerInterno}>
        <h1>{designer.name}</h1>
        <h4>{designer.description}</h4>
        {listaImagens}
      </div>
    </div>
  );

  const conteudoComFlechasDesktop = (
    <motion.div
      className={styles.conteudoFlechasDesktop}
      variants={{
        hidden: {
          opacity: 0,
          x: 266,
          transition: { duration: duracaoEfeitoImagem },
        },
        visible: {
          opacity: 1,
          x: 0,
          transition: { duration: duracaoEfeitoImagem },
        },
      }}
    >
      <motion.polygon
        className={styles.imagemDesigner}
        variants={wrapperVariants}
        initial="hidden"
        animate="visible"
      >
        <ImagemOuCarregando
          urlImagem={designer.pictures[0].url}
          aoCarregar={aoCarregarImagem}
          alt={`Imagem do designer ${designer.name}`}
        />
        {conteudoDesigner}
      </motion.polygon>
    </motion.div>
  );

  const btnVoltar = (
    <motion.div
      className={styles.btnVoltar}
      onClick={voltar}
      variants={{
        hidden: { x: -10, transition: { duration: 1 } },
        visible: { x: 0, transition: { duration: 1 } },
      }}
    >
      <ChevronLeft />
      <span>Voltar</span>
    </motion.div>
  );

  const conteudoComFlechas = (
    <>
      {isMobile && conteudoComFlechasMobile}
      {!isMobile && (
        <>
          {conteudoComFlechasDesktop}
          {btnVoltar}
        </>
      )}
    </>
  );

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <motion.div
        className={classeContainer}
        variants={{ hidden: { opacity: 0 }, visible }}
      >
        {conteudoComFlechas}
        <ModalCarregando carregando={carregando} />
      </motion.div>
    </motion.article>
  );
};

export default memo(Designer);
