import { useMemo } from "react";
import axios from "axios";
import { apiURL } from "./getAPIURL";

export const useApiSolicitacaoOrcamento = () => {
  // Use useMemo para memoizar a instância de api
  const api = useMemo(() => {
    const url = `${apiURL}/solicitacaoOrcamento`;
    const urlRecuperar = `${url}/findById?`;
    const OPTIONS = {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const listar = async (dados) => {
      const resposta = await axios.get(url, { params: { dados } }, OPTIONS);
      return resposta;
    };
    const cadastrar = async (dados) => {
      const resposta = await axios.post(url, dados, OPTIONS);
      return resposta;
    };
    const alterar = async (dados) => {
      const resposta = await axios.patch(url, dados, OPTIONS);
      return resposta;
    };
    const recuperarPorId = async (id) => {
      const urlId = `${urlRecuperar}id=${id}`;
      const resposta = await axios.get(urlId, OPTIONS);
      return resposta;
    };
    const deletar = async (id) => {
      const urlId = `${url}id=${id}`;
      const resposta = await axios.delete(urlId, OPTIONS);
      return resposta;
    };
    return {
      listar,
      cadastrar,
      alterar,
      recuperarPorId,
      deletar,
    };
  }, []); // Deps do useMemo

  return api;
};
