import { memo } from "react";
import { BotoesDePontos, useDotButton } from "./BotoesDePontos";
import { PrevButton, NextButton, usePrevNextButtons } from "./BotoesDeFlechas";
import useEmblaCarousel from "embla-carousel-react";
import "./ListaImagens.css";
import ImagemOuCarregando from "../ImagemOuCarregando/ImagemOuCarregando";
import VideoOuCarregando from "../VideoOuCarregando/VideoOuCarregando";
const ListaImagens = ({
  imagens = [{ src: "", tipo: "imagem" | "video", desabilitado: false }],
  aoClicar = (src) => {},
  opcoes = { slidesToScroll: "auto" },
  classeImagem = "imagemSlide",
}) => {
  const slides = imagens;
  const options = opcoes;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className="emblalistaimagens">
      <div className="emblalistaimagens__viewport" ref={emblaRef}>
        <div className="emblalistaimagens__container">
          {slides.map((imagem, index) => (
            <div
              className="emblalistaimagens__slide"
              key={index}
              onClick={() => aoClicar(imagem)}
            >
              <div className="emblalistaimagens__slide__number">
                {imagem.tipo != "video" && (
                  <ImagemOuCarregando
                    urlImagem={imagem.src}
                    className={classeImagem}
                  />
                )}
                {imagem.tipo === "video" && (
                  <VideoOuCarregando
                    urlImagem={imagem.src}
                    className={classeImagem}
                    desabilitado={imagem.desabilitado ?? false}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="emblalistaimagens__controls">
        <div className="emblalistaimagens__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="emblalistaimagens__dots">
          {scrollSnaps.map((_, index) => (
            <BotoesDePontos
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"emblalistaimagens__dot".concat(
                index === selectedIndex
                  ? " emblalistaimagens__dot--selected"
                  : "",
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default memo(ListaImagens);
