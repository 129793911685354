export const valorOrdenacaoProduto = {
  DESIGNER_CRESCENTE: "designer-crescente",
  DESIGNER_DECRESCENTE: "designer-decrescente",
  NOME_CRESCENTE: "nome-crescente",
  NOME_DECRESCENTE: "nome-decrescente",
};
export const camposOrdenacaoProduto = [
  {
    label: "Designer crescente",
    valor: valorOrdenacaoProduto.DESIGNER_CRESCENTE,
  },
  {
    label: "Designer decrescente",
    valor: valorOrdenacaoProduto.DESIGNER_DECRESCENTE,
  },
  { label: "Nome crescente", valor: valorOrdenacaoProduto.NOME_CRESCENTE },
  { label: "Nome decrescente", valor: valorOrdenacaoProduto.NOME_DECRESCENTE },
];
