export const categoriasProduto = [
  { value: "todos", label: "Todos", tamanho: "10px" },
  { value: "sofa", label: "Sofá", tamanho: "128px" },
  { value: "poltrona", label: "Poltrona", tamanho: "128px" },
  { value: "mesas-centro", label: "Mesas de centro", tamanho: "156px" },
  { value: "mesas-apoio", label: "Mesas de apoio", tamanho: "168px" },
  { value: "estantes", label: "Estantes", tamanho: "128px" },
  { value: "aparador", label: "Aparador", tamanho: "10px" },
  { value: "camas", label: "Camas", tamanho: "128px" },
  { value: "colchoes", label: "Colchões", tamanho: "221px" },
  { value: "mesas-cabeceira", label: "Mesas de cabeceira", tamanho: "12px" },
  { value: "mesas-jantar", label: "Mesas de jantar", tamanho: "12px" },
  { value: "cadeiras", label: "Cadeiras", tamanho: "221px" },
  { value: "banquetas", label: "Banquetas", tamanho: "221px" },
  { value: "mesas-escritorio", label: "Mesas de escritório", tamanho: "12px" },
  {
    value: "cadeiras-escritorio",
    label: "Cadeiras de escritório",
    tamanho: "12px",
  },
  {
    value: "mesas-cadeiras-jardim",
    label: "Mesas e cadeiras de jardim",
    tamanho: "12px",
  },
  { value: "espreguicadeiras", label: "Espreguiçadeiras", tamanho: "221px" },
  { value: "guarda-sois", label: "Guarda-sóis", tamanho: "12px" },
  { value: "bancos-jardim", label: "Bancos de jardim", tamanho: "12px" },
  { value: "bancos", label: "Bancos", tamanho: "12px" },
  { value: "buffets", label: "Buffets", tamanho: "12px" },
  { value: "cristaleiras", label: "Cristaleiras", tamanho: "12px" },
  { value: "vasos", label: "Vasos", tamanho: "221px" },
  { value: "mesa-jogos", label: "Mesa de jogos", tamanho: "12px" },
  { value: "moveis-modulares", label: "Móveis modulares", tamanho: "12px" },
  { value: "carrinho-bar", label: "Carrinho bar", tamanho: "12px" },
  { value: "luminarias", label: "Luminárias", tamanho: "221px" },
  { value: "abajour", label: "Abajour", tamanho: "221px" },
  { value: "adornos", label: "Adornos", tamanho: "221px" },
  { value: "obras-artes", label: "Obras de artes", tamanho: "221px" },
  { value: "tapetes", label: "Tapetes", tamanho: "221px" },
];
