export const querysTelaProdutos = {
  PRODUTOS: "produtos",
  PRODUTOS_API: "produtosApi",
  CATEGORIAS_MARCADA: "categoriaMarcada",
  PRODUTO_ID: "produtoId",
};

export const querysTelaHeader = {
  PRODUTOS: "produtosHeader",
  PRODUTOS_API: "produtosApiHeader",
  CATEGORIAS_MARCADA: "categoriaMarcadaHeader",
};
