import React from "react";
import styles from "./QuemSomos.module.scss";
import { motion } from "framer-motion";
import { Col, Row } from "reactstrap";
import images from "../../images";
import { useMobile } from "../../hooks/useMobile";

const QuemSomos = () => {
  const classeContainer = `container-fluid ${styles.container}`;
  const visible = { opacity: 1, y: 0, transition: { duration: 0.3 } };
  const estiloImg = `rounded-5 ${styles.imgAreaExterna}`;

  const { isMobile } = useMobile();

  const conteudo = (
    <Row className={styles.conteudo}>
      {isMobile && (
        <Col>
          <div className={styles.linhaMobile} />
        </Col>
      )}
      <Col lg={6}>
        <img
          src={images.areaInterna}
          alt="Imagem mostrando internamente a loja da voktum"
          className={estiloImg}
        />
      </Col>
      <Col lg={6}>
        <div className={styles.textosQuemSomos}>
          <h1>Quem somos</h1>
          <h3>Biografia</h3>
          <p>
            Conheça a Voktum, e descubra um novo conceito sobre morar. Com uma
            curadoria exclusiva de mobiliário de alto padrão para áreas
            internas, externas e ambientes corporativos, oferecemos sofisticação
            sem igual.
          </p>
          <p>
            O nome &quot;Voktum&quot;, derivado do Latim &quot;Voctum&quot; que
            significa &quot;A ENTRADA&quot;, simboliza nosso convite para
            explorar um novo estilo de vida. Em nossa nova loja de 1.500m²,
            criamos ambientes conceituais que capturam a
          </p>
          <p>
            Conheça móveis exclusivos que refletem seu perfil único. Nossa
            equipe de consultores está pronta para orientar sua escolha,
            garantindo que cada peça seja perfeitamente adequada ao seu espaço.
          </p>
          <p>
            Localizados no coração do Belvedere, nossa loja conceito de 3
            andares apresenta o melhor do mobiliário alto padrão. Destaque para
            peças assinadas por renomados designers, frequentemente exibidas em
            eventos como o iSaloni Del Mobile Milano.
          </p>
          <p>
            Na Voktum, o design, conforto e inovação se unem em cada produto.
            Com linhas próprias e colaborações exclusivas com designers de
            renome, estamos redefinindo o conceito de mobiliário de luxo.
          </p>
          <p>
            Experimente o próximo nível em sofisticação e atendimento
            excepcional. Descubra a Voktum e transforme sua ideia de lar.
          </p>
          <h3>nossos parceiros</h3>
          <p>
            As melhores referências do mercado mobiliário você encontra na
            Voktum!
          </p>
          <p>
            Garantimos a qualidade de nossos produtos e a satisfação de nossos
            clientes graças ao nosso atendimento diferenciado e às boas
            parcerias que fortificamos.{" "}
          </p>
          <p>
            Somos cuidadosos e atuamos ao lado de parceiros com valores
            semelhantes ao nosso, preocupando-se com sua responsabilidade
            ambiental, segurança e qualidade de seus materiais.
          </p>
          <p>
            Nossos parceiros oferecem o que há de melhor em móveis de alto
            padrão. Suas peças possuem design assinado, matéria prima
            diferenciada e técnicas únicas de produção. São empresas
            reconhecidas internacionalmente e que conquistaram importantes
            prêmios, como do ICFF de Nova York, iSalone del Milano e ABIMAD.
          </p>
          <p>Principais parceiros: UULTIS, MANÁ E MODALLE</p>
        </div>
      </Col>
    </Row>
  );

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <motion.div
        className={classeContainer}
        variants={{ hidden: { opacity: 0 }, visible }}
      >
        {conteudo}
      </motion.div>
    </motion.article>
  );
};

export default React.memo(QuemSomos);
