import { memo } from "react";
import styles from "./designersPorLetra.module.scss";
import { useMobile } from "../../hooks/useMobile";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import ListaImagensComFlecha from "../../component/ListaImagensComFlecha/ListaImagensComFlecha";
import ModalCarregando from "../../component/ModalCarregando/ModalCarregando";
import ListaImagensComFlechaVertical from "../../component/ListaImagensComFlechaVertical/ListaImagensComFlechaVertical";
import { motion } from "framer-motion";
import { useDesignersPorLetra } from "../../hooks/useDesignersPorLetra";

const DesignersPorLetra = () => {
  const classeContainer = `container-fluid position-relative ${styles.container}`;
  const { isMobile } = useMobile();
  const {
    carregando,
    imagensPorPrimeiraLetraDesigner,
    voltar,
    selecionarDesigner,
  } = useDesignersPorLetra();
  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

  const nomeDesigner = (imagem) => {
    return (
      <div className={styles.divNomeDesigner}>
        <div className={styles.nomeDesigner}>
          <ChevronLeft />
          <span>{imagem.designer.name}</span>
        </div>
      </div>
    );
  };

  const nomeDesignerMobile = (imagem) => {
    return (
      <div className={styles.nomeDesignerMobile}>
        <span>{imagem.designer.name}</span>
      </div>
    );
  };

  const tagTitulo = (
    <motion.div
      className={styles.tagTitulo}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1 } },
      }}
    >
      <span>designers</span>
    </motion.div>
  );

  const tituloDesktop = (
    <div className={styles.containerTitulo}>
      <motion.div
        className={styles.btnVoltar}
        variants={{
          hidden: { opacity: 0, x: -44, y: 50 },
          visible: { opacity: 1, x: 0, y: 0, transition: { duration: 0.5 } },
        }}
        onClick={voltar}
      >
        <ChevronLeft />
        <span>Voltar</span>
      </motion.div>
    </div>
  );

  const tituloMobile = (
    <div className={styles.containerTitulo}>
      {
        <>
          <div className="linha" />
          {tagTitulo}
        </>
      }
    </div>
  );
  const tituloPagina = (
    <>
      {isMobile && tituloMobile}
      {!isMobile && tituloDesktop}
    </>
  );

  const conteudoComFlechasMobile = (
    <div className={styles.conteudoComFlechasMobile}>
      <ListaImagensComFlechaVertical
        imagens={imagensPorPrimeiraLetraDesigner}
        insereConteudoNaImagem={nomeDesignerMobile}
        opcoes={{ axis: "y", slidesToScroll: "auto" }}
      />
    </div>
  );

  const conteudoComFlechasDesktop = (
    <motion.div
      className={styles.conteudoFlechasDesktop}
      variants={{
        hidden: { opacity: 0, x: 166, transition: { duration: 0.5 } },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
      }}
    >
      <ListaImagensComFlecha
        imagens={imagensPorPrimeiraLetraDesigner}
        conteudoSomenteNoHover={nomeDesigner}
        efeitoHover=""
        efeitoHoverNaImagem="preto"
        aoClicar={selecionarDesigner}
      />
    </motion.div>
  );

  const conteudoComFlechas = (
    <>
      {isMobile && conteudoComFlechasMobile}
      {!isMobile && conteudoComFlechasDesktop}
    </>
  );

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <motion.div
        className={classeContainer}
        variants={{ hidden: { opacity: 0 }, visible }}
      >
        {tituloPagina}
        {conteudoComFlechas}
        <ModalCarregando carregando={carregando} />
      </motion.div>
    </motion.article>
  );
};

export default memo(DesignersPorLetra);
